import React, {FunctionComponent} from "react"
import courseService from "../services/course";
import newCourseService from "../services/course_service";
import AuthenticatedLayout from "../components/layouts/authenticatedLayout"
import {Container, Grid} from "@material-ui/core";
import OverviewStep from "../components/overview/step";
import OverviewPath from "../components/overview/path";
import SectionPreviewComponent from "../components/course-browser/section-preview";
import {listAllVideoURLs} from "../services/createVideosJson";
import {createVideosJson} from "../services/createVideosJson";


const VideoLibraryJson: FunctionComponent = () => {

    const steps = Object.keys(courseService.steps);
    const paths = Object.keys(courseService.paths);
    const sections = Object.keys(newCourseService.sections);
    console.log(newCourseService.sections)
    let vs = createVideosJson();
    let video_list = listAllVideoURLs();

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    var unique = video_list.filter(onlyUnique);

    console.log(JSON.stringify(unique, null, 2));
    let video_list_string = unique.map((item) =>
                <li key={item}>{item}</li>
    )
    return (
        <AuthenticatedLayout title="Overview">
            <Container>
                <pre>{video_list_string}</pre>
            </Container>
        </AuthenticatedLayout>
    )
}

export default VideoLibraryJson
