import React from "react";
import courseService from "./course";
import OverviewPath from "../components/overview/path";

function constructVideoSource(step, sectionKey) { //copied from old app
    //$rootScope.currentVideoFile = step;
    const videoBase = 'http://dh5ycesfech6i.cloudfront.net';
    const personKeys = ['eva', 'hailey', 'isabel', 'lauren', 'maya', 'nicki', 'jessica'];
    const openKeys = ['open4noquit.mp4', 'open2.mp4', 'open3.mp4'];
    const jessicaKeys = ['kyb.mp4','bcintro.mp4','gynecog1.mp4','gynecog2.mp4','condoms.mp4'];
    //const localLesson = localStorageService.get('Lesson');
    let videoSubdir;

    if(personKeys.indexOf(sectionKey) > -1) {
        videoSubdir = sectionKey[0].toUpperCase() + sectionKey.slice(1);
    } else if(sectionKey.match(/^sti/)) {
        videoSubdir = 'STIs';
    } else if(sectionKey === 'birthcontrol') {
        videoSubdir = 'Jessica';
    } else if(sectionKey === 'kyb') {
        videoSubdir = 'know_your_body';
    } else if(sectionKey.includes('open') || sectionKey === 'close') {
        videoSubdir = 'Open';
    }
    else if(sectionKey.indexOf('lesson') > -1) {
        if(jessicaKeys.indexOf(step) > -1) {
            videoSubdir = 'Jessica';
        } else if(openKeys.indexOf(step) > -1) {
            videoSubdir = 'Open';
        } else if(sectionKey.charAt(0) == 'e' || sectionKey.indexOf('makeupes') > -1) {
            videoSubdir = 'eat_smart';
        } else {
            videoSubdir = 'lessons';
        }
    }
    else {
        //throw `Invalid section key! ${sectionKey}`;
        console.log(`Invalid section key! ${sectionKey}`)
    }

    return {
        src: `${videoBase}/${videoSubdir}/${step}`,
        type: 'video/mp4'
    };
}

const createVideosJson = () => {
    const paths = Object.keys(courseService.paths);
    const videos = new Object();
    paths.forEach((pathKey, index) => {
        let seq = Object.keys(courseService.paths[pathKey]);
        let sectionKey = pathKey;
        seq.forEach((seqKey, index) => {
            courseService.paths[pathKey][seqKey].steps.forEach((step, index) => {
                if(step.indexOf("mp4") > -1) {
                    videos[step.toString()] = constructVideoSource(step,sectionKey);
                }
            })
        })
    })
    console.log(JSON.stringify(videos,null,2))
    return videos
}

const listAllVideoURLs = () => {
    const paths = Object.keys(courseService.paths);
    const videos = new Array();
    paths.forEach((pathKey, index) => {
        let seq = Object.keys(courseService.paths[pathKey]);
        let sectionKey = pathKey;
        seq.forEach((seqKey, index) => {
            courseService.paths[pathKey][seqKey].steps.forEach((step, index) => {
                if(step.indexOf("mp4") > -1 ) {
                    let src = constructVideoSource(step,sectionKey).src;
                    if(sectionKey.charAt(0) == 'e' || sectionKey.indexOf('makeupes') > -1) {
                    } else {
                        videos.push(src + ' ' + step);
                    }
                }
            })
        })
    })
    console.log(videos);
    return videos
}

export {createVideosJson, listAllVideoURLs}
